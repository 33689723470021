import * as React from "react"
import { Row, Col } from "react-bootstrap"
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")
SwiperCore.use([Navigation, Pagination, Autoplay])

const ClientsComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        clients {
          id
          title
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 50
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  `)
  const clients = data.api.clients

  return (
    <>
      <Row>
        <Col className="mb-4">
          <div className="title">
            <h3 className="gradient-text">Clientes</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Swiper
            spaceBetween={25}
            slidesPerView={1}
            navigation
            pagination
            autoplay
            loop
            className="px-3 clients-slider"
            breakpoints={{
              900: {
                slidesPerView: 3,
              },
            }}
          >
            {clients.map((client, idx) => {
              if (!client.image) {
                return null
              }
              return (
                <React.Fragment key={`clients-${idx}`}>
                  {client.image && (
                    <SwiperSlide style={{ paddingTop: 10 }}>
                      <div className="d-flex justify-content-center align-items-center">
                        <GatsbyImage
                          image={
                            client.image.imageFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt=""
                          className="align-self-center"
                        />
                      </div>
                    </SwiperSlide>
                  )}
                </React.Fragment>
              )
            })}
          </Swiper>
        </Col>
      </Row>
    </>
  )
}

export default ClientsComponent
