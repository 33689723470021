import * as React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Particles from "react-particles-js"

const HeroComponent = () => {
  return (
    <div className="hero">
      <div className="particlesContainer">
        <Container className="h-100">
          <Row className="align-items-center h-100">
            <Col md={6} style={{ marginTop: -55 }}>
              <div className="text-wrapper">
                <h1 className="gradient-text font-weight-700">Inteligência</h1>
              </div>
              <div className="text-wrapper">
                <h1 className="gradient-text font-weight-300">Que integra.</h1>
              </div>
              <div className="text-wrapper">
                <h1 className="gradient-text font-weight-700">Tecnologia</h1>
              </div>
              <div className="text-wrapper">
                <h1 className="gradient-text font-weight-300">Que aproxima.</h1>
              </div>
              <div style={{ zIndex: 1 }} className="d-flex">
                <Link className="btn btn-primary mt-4" to="/contato">
                  Fale conosco
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Particles params={particles} className="bgParticles" />
    </div>
  )
}

export default HeroComponent

// let particles = {
//   particles: {
//     number: {
//       value: 115,
//     },
//     size: {
//       value: 3,
//     },
//     move: {
//       speed: 6,
//     },
//     links: {
//       color: "#FFF",
//       distance: 120,
//       enable: true,
//       opacity: 0.1,
//       width: 1,
//     },
//     // line_linked: {
//     //   shadow: {
//     //     enable: true,
//     //     color: "#FFF",
//     //     blur: 5,
//     //   },
//     //   color: {
//     //     value: "#DFE3EE",
//     //   },
//     // },
//   },
//   interactivity: {
//     events: {
//       onhover: {
//         enable: true,
//         mode: "repulse",
//         foce: 2,
//         smooth: 20,
//         distance: {
//           value: 500,
//         },
//       },
//     },
//     modes: {
//       repulse: {
//         distance: 100,
//         duration: 0.4,
//         speed: 1
//       }
//     }

//   },
// }

let particles = {
  particles: {
    number: { value: 80, density: { enable: true, value_area: 800 } },
    color: { value: "#ffffff" },
    shape: {
      type: "circle",
      stroke: { width: 0, color: "#000000" },
      polygon: { nb_sides: 5 },
      image: { src: "img/github.svg", width: 100, height: 100 },
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
    },
    size: {
      value: 3,
      random: true,
      anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: "#ffffff",
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 6,
      direction: "none",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: { enable: false, rotateX: 600, rotateY: 1200 },
    },
  },
  interactivity: {
    detect_on: "window",
    events: {
      onhover: { enable: true, mode: "repulse" },
      onclick: { enable: false, mode: "push" },
      resize: true,
    },
    modes: {
      grab: { distance: 400, line_linked: { opacity: 1 } },
      bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
      repulse: { distance: 200, duration: 0.4 },
      push: { particles_nb: 4 },
      remove: { particles_nb: 2 },
    },
  },
  retina_detect: true,
}

if (typeof window !== "undefined" && window.innerWidth < 900) {
  particles = {
    particles: {
      number: {
        value: 40,
      },
      size: {
        value: 1,
      },
      move: {
        speed: 5,
      },
      links: {
        color: "#FFF",
        distance: 90,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "repulse",
          foce: 2,
          smooth: 20,
          distance: {
            value: 500,
          },
        },
      },
    },
  }
}
