import React from "react"
import { Container, Modal } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import About from "../components/about"
import Hero from "../components/hero"
import Cta from "../components/common/cta"
import Products from "../components/products"
import Testimonials from "../components/testimonials"
import Clients from "../components/clients"
import Manufacturers from "../components/manufacturers"
import Contact from "../components/contact"

const IndexPage = () => {

  // const [modalShow, setModalShow] = React.useState(true)

  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <div className="about padding-top padding-bottom">
        <Container>
          <About />
          <Products />
        </Container>
      </div>
      <div className="white-bg">
        <Container className="padding-top half-padding-bottom">
          <Testimonials />
        </Container>
        <Container className="padding-bottom">
          <Clients />
        </Container>
      </div>
      <Manufacturers />
      <Cta />
      <Contact />
      {/* {(new Date().getTime() < new Date("01/03/2022").getTime()) && (
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )} */}
    </Layout>
  )
}

export default IndexPage

// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="popup-modal"
//       onClick={props.onHide}
//     >
//       <Modal.Body>
//         <StaticImage src="../images/popup_ano_novo.png" layout="fullWidth" quality={90} alt="Feliz ano novo!" />
//       </Modal.Body>
//     </Modal>
//   )
// }