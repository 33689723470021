import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ManufacturersComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        manufacturers {
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  width: 180
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="gradient-bg">
      <Container className="padding-top padding-bottom">
        <Row className="mb-3">
          <Col>
            <div>
              <div className="title">
                <h3 className="gradient-text">Fabricantes</h3>
              </div>
            </div>
            <p>
              Contamos com a parceria dos principais fabricantes de
              infraestrutura em TIC. Eles nos apoiam no desenvolvimento de
              projetos que atendam às necessidades de cada cliente. São
              parceiros de renome mundial que, com aptidão e know-how de
              mercado, agregam à bagagem que a Unnit traz ao longo dos anos para
              atendê-lo com excelência.
            </p>
          </Col>
        </Row>
        <Row className="row-cols-5 justify-content-center">
          {data.api.manufacturers.map((manufacturer, idx) => {
            return (
              <React.Fragment key={`manufacturer-${idx}`}>
                <Col xs={6} md={3} className="text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <GatsbyImage
                      image={
                        manufacturer.image.imageFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt=""
                    />
                  </div>
                </Col>
              </React.Fragment>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default ManufacturersComponent
